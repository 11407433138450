import gql from 'graphql-tag'


const FINALIZE_FORM = gql`mutation finalizeForm(
    $form_answer_id: String!,
    $tenant_id: String!,
    $completed_by_id: String!
) {
    finalizeForm(form_answer_id: $form_answer_id, tenant_id: $tenant_id, completed_by_id: $completed_by_id) {
        data
    }
}`

export {
    FINALIZE_FORM
}
