<template lang="pug">
    .flex.flex-row.justify-between
        v-btn(
            v-if="!isFinished"
            depressed
            color="success"
            class="v-btn--flat inline w-150"
            :class="{'disabled pointer-events-none': loading}"
            :disabled="disabled && !isComplete"
            @click="finishForm(emit)"
        )
            fa-icon( v-if="loading" icon="spinner" spin class="mr-2 text-primary-light" )
            div( v-html="finishBtnText" )
</template>

<script>
import {ref, computed} from '@vue/composition-api'
import {FORM_STATUS} from '@/modules/constants'

const getButtonName = (btnType) => {
    switch(btnType) {
        case '':
        case null:
        case FORM_STATUS.Incomplete:
            return 'Finish &amp; Score'
        case FORM_STATUS.Complete:
            return 'Re-open'
        default:
            return 'Finalise'
    }
}

const finishForm = (emit) => {
    // console.log('event', event)
    emit('finish-form')
}

export default {
    name: 'FormActions',
    props: {
        formAnswerId: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isComplete: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const finaliseBtnText = ref('')
        // const finishBtnText = ref('')
        const formStatus = computed(() => props.status)

        finaliseBtnText.value = getButtonName()
        const finishBtnText = computed(() => {
            return props.loading ? 'Please Wait...' : getButtonName(props.status)
        })

        const finishButtonColour = computed(() => {
            return formStatus.value !== FORM_STATUS.Complete ? 'success' : 'primary'
        })

        const isFinished = computed(() => {
            return formStatus.value === FORM_STATUS.Complete
        })

        return {
            finishBtnText,
            finaliseBtnText,
            emit,
            formStatus,
            finishButtonColour,
            isFinished,
            finishForm,
        }
    }
}
</script>
