import {format, parseISO} from 'date-fns'

const percentage = (totalComplete, totalQuestions) => {
    return Math.floor(((totalComplete || 0) / (totalQuestions || 1)) * 100)
}

const getStatus = (totalComplete, totalQuestions) => {
    return totalComplete === totalQuestions && totalQuestions != null ? 'Completed' : 'Incomplete'
}

const getFormattedDate = (dueDate) => {
    return dueDate ? format(parseISO(dueDate), 'dd/MM/yy') : ''
}

export {
    percentage,
    getStatus,
    getFormattedDate
}
