<template lang="pug">
    .nps-container
        v-form(
            v-model="isValid"
            ref="formRef"
            @submit.prevent=""
        )
            .m-auto.mt-8
                .m-auto.w-400.l-box-shadow.rounded.py-8.px-10.text-left
                    div
                        | Enter your Account ID to get started
                    v-text-field.mt-2(
                        v-model="tenantShortName"
                        label="Account ID"
                        placeholder=""
                        autocapitalize="none"
                        :rules="[required]"
                    )
                    v-btn.mt-2(
                        :disabled="loading"
                        @click="checkShortNameAndGo"
                    )
                        fa-icon(v-if="loading" icon="spinner" spin class="mr-2")
                        | {{ btnText }}

                    .flex.flex-row.justify-end.mt-8(v-if="showAlert")
                        v-alert(
                            :closable="false"
                            :type="type"
                            class="text-sm w-full"
                        ) {{ message }}
</template>

<script>
import {computed, onMounted, ref} from '@vue/composition-api'
import store from '@/store'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {useLazyQuery} from '@vue/apollo-composable'
import {GET_TENANT_BY_SHORT_NAME} from '@/graphql/queries/tenantQueries.gql'
import camelcaseKeys from 'camelcase-keys'
import {useFormFns} from '@/composables/form-fns'

const { useActions } = createNamespacedHelpers('')

export default {
    name: 'KioskLoginView',
    setup(_, context) {
        const tenantShortName = ref('')
        const isValid = ref(true)
        const formRef = ref({})
        const showAlert = ref(false)
        const type = ref('')
        const message = ref('')
        const redirecting = ref(false)
        const route = context?.root.$route
        const router = context?.root.$router
        const { setShowTenantBranding } = useActions(['setShowTenantBranding'])
        const { required } = useFormFns()
        const tenantLoaded = ref(false)

        const params = computed(() => {
            return {shortName: tenantShortName.value}
        })
        const btnText = computed(() => redirecting.value ? 'Launching form, please wait...' : 'Launch')

        //#region Get Tenant
        const {
            onResult: getTenantOnResult,
            onError: getTenantOnError,
            refetch: getTenantRefetch,
            load: loadTenant,
            loading
        } = useLazyQuery(GET_TENANT_BY_SHORT_NAME, params)
        getTenantOnResult(async ({ data, loading: queryLoading }) => {
            // if the query is still loading, then we wait for it to finish
            if (queryLoading) return
            const result = camelcaseKeys(data?.tenant, {deep:true})
            if (result?.length > 0) {
                const tenant = result[0]
                // now let's do something when we find the tenant
                redirecting.value = true
                await goToNpsForm(tenant.shortName)
            } else {
                showAlert.value = true
                type.value = 'error'
                message.value = 'Your account information is incorrect. Please try again.'
            }
            tenantLoaded.value = true
        })

        getTenantOnError((error) => {
            console.log('error', error)
            showAlert.value = true
            type.value = 'error'
            message.value = 'Something went wrong, please try reloading the page.'
            tenantLoaded.value = true
        })
        //#endregion

        const goToNpsForm = async (shortName) => {
            return router.push({ name: 'kiosk-nps-feedback', params: { shortName }})
        }

        const checkShortNameAndGo = () => {
            //TODO: check the shortname against hasura
            if (!validate()) return
            message.value = ''
            showAlert.value = false
            type.value = ''
            if (!tenantLoaded.value) loadTenant()
            else getTenantRefetch({shortName: tenantShortName.value})
        }

        const validate = () => {
            isValid.value = formRef.value.validate()
            return isValid.value
        }

        onMounted(() => {
            //setting the formHeaderType
            store.commit('SET_FORM_PAGE_HEADER_TYPE', route?.meta?.headerType)
            setShowTenantBranding(true)
        })

        return {
            tenantShortName,
            loading,
            showAlert,
            message,
            type,
            isValid,
            formRef,
            required,
            redirecting,
            btnText,
            checkShortNameAndGo
        }
    }
}
</script>
