<template lang="pug">
    div
        div.nps-container(v-if="showPrint")
            v-btn(
                depressed
                class="w-100"
                :disabled="isPrinting"
                @click="printForm()"
            )
                | {{ printBtnText }}
        div(ref="elemToPrint" class="nps-container" :class="{'container m-auto my-3 p-0 m-0': isExternal}")
            FormHeader(
                v-if="!isLoading && isExternal && showHeader"
                :title="title"
                :form-due-date="dueDate"
                :percent-done="percentDone"
                :form-answer-id="formAnswerId"
                :tenant-id="tenantId"
                :status="status"
                :auth0-id="auth0Id"
                :scores="scoreData"
                @status-update="updateFormAnswerStatusHandler"
            )
            Form(
                :form-props="formPropsFromRoute"
                :form-title.sync="title"
                :read-only="readOnly"
                @update-form-stats="updateStats"
                @update-form-answer-status="updateFormAnswerStatusHandler"
            )
</template>

<script>
import {onMounted, reactive, ref, computed} from '@vue/composition-api'
import Form from '@/components/forms/Form'
import FormHeader from '@/components/forms/partials/FormHeader'
import camelcaseKeys from 'camelcase-keys'
import { logErrorMessages } from '@vue/apollo-util'
import {percentage} from '@/components/shared/modules/forms'
import {useLazyQuery} from '@vue/apollo-composable'
import {GET_FORM_ANSWER_STATS} from '@/graphql/queries/formAnsweFunctions.gql'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import store from '@/store'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import html2pdf from 'html2pdf.js'
import {usePrintFormStore} from '@/stores/usePrintFormStore'

const { useActions, useGetters } = createNamespacedHelpers('')

const onErrorCallBack = (error) => {
    console.log('error that happened')
    logErrorMessages(error)
}

export default {
    name: 'DynamicFormView',
    components: {Form, FormHeader},
    setup(_, context) {
        const elemToPrint = ref({})
        const tenantStylesStore = useTenantStylesStore()
        const printFormStore = usePrintFormStore()
        const { updateIsPrinting } = printFormStore
        const { isPrinting } = storeToRefs(printFormStore)
        const { loadFormStatsOnForm, disableForms } = storeToRefs(tenantStylesStore)
        const { setThankYouRedirectQueryParams, setModalOpen, setModalClose } = useActions(['setThankYouRedirectQueryParams', 'setModalOpen', 'setModalClose'])
        const route = context?.root.$route
        const params = route?.params
        const query = route?.query
        let refetchQuery = reactive({})
        const title = ref('Patient Form')
        const percentDone = ref(0)
        const formAnswerId = ref('')
        // const tenantId = ref('')
        const isExternal = ref(false)
        let isLoading = ref(true)
        let errorText = ref('')
        const dueDate = ref('')
        const showHeader = ref(true)
        const formPropsFromRoute = ref(params)
        const status = ref('')
        const showPrint = computed(() => !!query.pr)
        const readOnly = computed(() => !!query.ro || disableForms.value)
        const formSetId = ref(query.fsi)
        const practiceName = ref(query.practiceName)
        const auth0Id = ref(query?.uid)
        const { tenant } = useGetters(['tenant'])
        const { setReadOnly, setIsExternal } = useActions(['setReadOnly', 'setIsExternal'])
        const scoreData = ref({})

        const tenantId = computed(() => query?.tenant || tenant.value?.tenantId)
        const printBtnText = computed(() => isPrinting.value ? 'Please wait...' : 'Print')
        setReadOnly(readOnly.value)

        formAnswerId.value = params?.formAnswerId || query?.fai
        isExternal.value = !!query?.ext
        showHeader.value = !query?.nh
        setIsExternal(isExternal.value)

        const updateFormAnswerStatusHandler = (val) => {
            status.value = val
        }

        const onResultCallBack = ({ data }) => {
            const stats = data?.formAnswerStats?.stats
            if (!stats) return

            const keys = Object.keys(stats)
            const key = keys[0]
            const stat = camelcaseKeys(stats[key], {deep: true})
            percentDone.value = percentage(stat?.completedQuestions, stat?.maxQuestions)
            dueDate.value = stat?.dueDate || ''
            scoreData.value = stat?.score || {}
        }

        const openModal = () => {
            setModalOpen({
                isVisible: true,
                data: {
                    keepOpen: true,
                    modalContentClass: 'w-2/5 h-32',
                },
                componentName: 'ModalShowPrinting'
            })
        }

        const printForm = () => {
            updateIsPrinting(true)
            openModal()
            const filename = `${title.value.replace(/ /g, '-')?.toLowerCase()}.pdf`
            const options = {
                margin: 1,
                filename: filename,
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { orientation: 'p', unit: 'mm', format: 'a4' }
            }
            setTimeout(() => {
                html2pdf()
                    .set(options)
                    .from(elemToPrint.value)
                    .save()
                    .then(() => {
                        setModalClose()
                        updateIsPrinting(false)
                    }, () => {
                        setModalClose()
                        updateIsPrinting(false)
                    })
            }, 3000)

        }

        const { onResult, loading, error, refetch, load, onError } = useLazyQuery(GET_FORM_ANSWER_STATS, {
            formAnswerIds: [formAnswerId.value],
            tenantId: tenantId.value
        })

        onResult(onResultCallBack)
        onError(onErrorCallBack)
        isLoading.value = loading.value
        refetchQuery = refetch
        errorText = error
        const updateStats = () => {
            if (loadFormStatsOnForm.value) refetchQuery()
        }

        // life cycle hooks
        onMounted(() => {
            //setting the formHeaderType
            store.commit('SET_FORM_PAGE_HEADER_TYPE', route?.meta?.headerType)
            if (loadFormStatsOnForm.value && formAnswerId.value && tenantId.value) load()
            else isLoading.value = false

            setThankYouRedirectQueryParams({
                fsi: formSetId.value,
                tenant: tenantId.value,
                practiceName: practiceName.value
            })
        })

        return {
            title,
            formPropsFromRoute,
            isExternal,
            percentDone,
            refetchQuery,
            isLoading,
            errorText,
            dueDate,
            formAnswerId,
            tenantId,
            status,
            showHeader,
            readOnly,
            auth0Id,
            scoreData,
            elemToPrint,
            printBtnText,
            isPrinting,
            showPrint,
            updateStats,
            updateFormAnswerStatusHandler,
            printForm
        }
    }
}
</script>
